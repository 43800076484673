
.icon-section {
    margin-top: 25px;

    img {
        opacity: .6;
        width: 38px;
        cursor: pointer;
        margin-right: 12px;
        transition: all .25s;

        &:hover {
            opacity: 1;
            transform: scale(1.1);
        }
    }

}