.about-section {
    padding: 70px 0 120px;

    .row {
        flex-direction: column-reverse;

        @media screen and (min-width: 768px) {
            flex-direction: row;
        }
    }

    p {
        font-size: 20px;
        line-height: 2;
        color: #cfbcd3;
        margin-bottom: 30px;
    }

    .image-container {
        text-align: center;
        isolation: isolate;
        width: 100%;
        max-width: 200px;
        position: relative;
        margin: 20px auto;

        @media screen and (min-width: 768px) {
            margin: -40px auto 0;
            width: 100%;
            max-width: 400px;

            &:hover {
                &:after {
                    opacity: 0;
                    transform: scale(1.2);
                }
            }
        }


        
        
        &:after {
            position: absolute;
            width: 100%;
            height: 100%;
            background: linear-gradient(45deg, #F900BF, #9900F0, #4700D8);
            left: 0;
            top: 0;
            z-index: 5;
            border-radius: 20px;
            content: "";
            transition: all .25s;

            @media screen and (min-width: 768px) {
                left: 8%;
                top: 4%;
            }
        }

        .about-image {
            width: 100%;
            border-radius: 20px;
            mix-blend-mode: screen;
            position: relative;
            z-index: 6;
            // border: 8px solid #f100c3;
            // box-shadow: 36px 23px 7px rgb(0 0 0 / 75%);
        }
    }

}