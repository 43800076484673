.project {
    padding: 25px 0;
    margin: 40px 0 100px;
    position: relative;

    @media screen and (min-width: 992px) {
        position: static; 
    }

    img {
        width: 100%;
        border-radius: 10px;
    }

    h3 {
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 4px;
    }

    p {
        margin-bottom: 0px;
    }

    .project-btn {
        background: linear-gradient(45deg, #c33ebd, #743aae);
        display: block;
        border-radius: 100px;
        transition: all .3s;

        width: 80px;
        height: 80px;
        padding: 17px;
        position: absolute;
        right: 0px;
        bottom: -20px;
        left: 0px;
        margin: 0 auto;

        @media screen and (min-width: 992px) {
            width: 160px;
            height: 160px;
            padding: 50px;
            margin: 20px auto 0;
            position: static;
        }

        @media screen and (min-width: 1200px) {
            margin: 100px auto 0;
        }

        &:hover {
            filter: hue-rotate(-35deg);
            transform: translateX(10px);
        }
    }

    .lower-row {
        justify-content: end;
        align-items: end;
        text-align: left;

        .lower-row-container {
            // background: #fff;
            background: linear-gradient(45deg, #1A1A40, #110038, #200a2e);
            margin: -60px 0px 0px;
            padding: 40px;
            border-radius: 0 0 10px 10px;

            @media screen and (min-width: 768px) {
                margin: -120px 0px 0px;
            }

            @media screen and (min-width: 992px) {
                border: 2px solid #aa3db8;
                border-radius: 10px;
            }

            * {
                color: #fff;
            }
        }
    }

    &.reverse {
        .row {
            flex-direction: row-reverse;

            &.lower-row {
                justify-content: start;
            }
        }
    }
}
