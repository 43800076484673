.footer {
    background: #1d0830;
    position: relative;
    text-align: center;
    padding: 20px 0;

    p {
        margin: 0px;
        padding: 0px;
    }
}