body {
    background: linear-gradient(45deg, #1A1A40, #110038, #200a2e, #36001f);
    color: #fff;
    font-family: 'Trispace', sans-serif;
    -ms-overflow-style: auto;
    scrollbar-color: #333 #f100c3;
    scrollbar-width: thin;
    cursor: url("https://assets.codepen.io/1536231/pizza.png") 1 1, pointer;
}

* {
    color: #fff;
}

.pos-rel {
    position: relative;
    z-index: 2;
}

body::-webkit-scrollbar-thumb {
    background-color: #f100c3;
}
body::-webkit-scrollbar-track {
    background-color: #333;
}
body::-webkit-scrollbar-corner {
    background-color: #333;
}
body::-webkit-scrollbar {
    width: 0.75rem;
    height: 0.5rem;
}