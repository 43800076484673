.subheader {
    padding: 15px 0;
    h2 {
        font-size: 2rem;
        text-transform: uppercase;
        position: relative;
        font-weight: 600;
        letter-spacing: 4px;

        &:after {
            content: "";
            position: absolute;
            top: 15px;
            height: 8px;
            width: 90px;
            margin-left: 20px;
            background: linear-gradient(45deg, #F900BF, #9900F0, #4700D8);
            border-radius: 5px;
        }

        @media screen and (min-width: 992px) {

            font-size: 2.5rem;

            &:after {
                width: 200px;
                top: 20px;
            }

        }

    }
}
