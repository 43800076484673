.contact-form {
    margin-top: 30px;
    margin-bottom: 120px;

    input, select, textarea {
        width: 100%;
        display: block;
        margin: 0 0 30px;
        background: none;
        border: none;
        border-bottom: 1px solid #fff;
        padding: 12px 2px;
        outline: none;
        box-shadow: none;
    }

    input::placeholder, textarea::placeholder {
        color: #fff;
    }

    textarea {
        min-height: 150px;
    }

    .btn-container {
        text-align: right;
        button {
            background: linear-gradient(45deg, #F900BF, #9900F0, #4700D8);
            color: #fff;
            border: none;
            padding: 20px 40px;
            border-radius: 8px;
            transition: filter .3s;

            &:hover {
                filter: hue-rotate(-35deg);
            }
        }
    }
}